import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({     
    resources: {
      en: {
        translation: {  
          "hero": {
            "part1": "AI polling tool for your live conferences!",
            "part2": "\u{1F680} We Revolutionize Crowd Feedback Gathering",
            "part3": "Are you truly tuned in to your audience?",
            "part3a": "",
            "part4": "Try Tendbe"
          },
          "testimonials_title": "Testimonials",
          //Testimonials
          "testimonials_author_01":"Ana Alvarez",
          "testimonials_org_01":"Migrapreneur | CEO & Co-Founder",
          "testimonials_desc_01": "\"I partnered with Tendbe team for an event and was impressed by their exceptional reporting and analysis. In today's data-driven world, their insights are invaluable. Well done, Tendbe!\"",
          //
          "howitworks_title": "Step into a future of effortless insights",
          "howitworks_description": "A game changer in event engagement, Tendbe redefines the landscape with its 100% automated, humanless opinion collection. Experience the power of AI, automated data reporting, QR voting, and precision-driven data aggregation tailored to resonate with your audience all while preserving their anonymity and privacy!",
          "howitworks_title_01": "Create your poll",
          "howitworks_title_02": "Create your channel",
          "howitworks_title_03": "Share",
          "howitworks_title_04": "Get your opinions",
          "howitworks_title_05": "Get your TendbeStats",
          "howitworks_description_01": "Create polls using text, images or video, simple and fast, Tendbe will become your favorite polling tool.",
          "howitworks_description_02": "Gather your polls in themed channels, retain and interact with your audience, make your channels a never ending source of opinions.",
          "howitworks_description_03": "Generate links and QR codes for your polls and channels, share them in the right spots, Tendbe helps you target your audience efficiently.",
          "howitworks_description_04": "View poll results in clear and intuitive fashion, receive comments with valuable feedback, use these information to enhance decision making.",
          "howitworks_description_05": "Dive into your poll results using data provided willingly by pollers, visualize your audience’s statistics such as gender, age structure, geographic locations, voting time, and more.",
          "features": "FEATURES",
          "features_title_01": "Collect real-time opinions",
          "features_title_02": "No more accounts!",
          "features_title_03": "Instant QR voting & commenting",
          "features_title_04": "AI powered data reports",
          "features_title_05": "No accounts or human interaction needed",
          "features_title_06": "Privacy",
          "features_description_01": "Following the best practices of agility, put your customers in the center of your brand activities, collect: Opinions, written feedback as well as impersonal data and tailor your products/services to their needs.",
          "features_description_02": "Stop bothering your crowd with installing an app, or asking them emails and phone numbers, if they have a phone they can vote, it's as simple as that!",
          "features_description_03": "Use our QR voting feature to efficiently collect anonymous opinions in specific locations and during your in-person events! Voters can also write anonymous comments for even more in-depth insights!",
          "features_description_04": "Get the best insights from your poll results by automatically generating data reports with graphs and numbers to help you make decisions reflecting your audience expectations!",
          "features_description_05": "The now famous: “Please sign in to fill this form, your opinion matters to us!” Or the “May I have a moment of your time sir, I just need your phone number and address for this survey” Tendbe is easy, fun and 100% confidential!",
          "features_description_06": "Our business model doesn’t rely on selling personal data, all voting is done anonymously. It is impossible to trace back impersonal data entered by voters to their real identity.",
          "sliderIllustrations": "Are you still struggling trying to decipher your event attendees' thoughts? Do you need to know your crowd’s preferences and their tendencies? Are you eager to capture insights in a comprehensive and statistical manner without compromising privacy? Tendbe is your strategic partner, revolutionizing feedback collection with the power of AI!",
          "videos": "The many ways to use Tendbe",
          "team": {
            "title": "OUR STARTUP ADVENTURE",
            "description": "Founded in 2021, Tendbe was built with passion by a team of entrepreneurs and digital nomads from around the world! Our mission is to bring the ease and fun of the digital voting experience to the real world and democratize the use of data while preserving privacy, 2 traditionally opposed concepts that we have successfully merged into 1 single tool! Our journey was a long tedious one filled with happy walks and harsh mountain climbing, but the pleasure and commitment are still as strong as day one!"
          },
          "trusted_title": "THEY TRUSTED US!",
          "faqs.title": "FREQUENTLY ASKED QUESTIONS",
          "faqs_question_01": "What is Tendbe?",
          "faqs_question_02": "Is Tendbe free to use? How do you guys make money?",
          "faqs_question_03": "What kind of data do you guys collect? ",
          "faqs_question_04": "",
          "faqs_question_05": "How does Tendbe prevent fake votes and fraudulent results?",
          "faqs_question_06": "¿What Tendbe does with my data?",
          "faqs_question_07": "As a Tendbe B2B client What happens when I delete my account?",
          "faqs_question_08": "Will my comments be deleted once my account is gone?",
          "faqs_question_09": "",
          "faqs_question_10": "As a voter what happens to the comments I leave on polls?", //"Can Tendbe help me generate statistics and graphs from my polls?",
          "faqs_answer_01": "Tendbe is an AI supported real-time in-person voting tool targeting events attendees and audiences.Collect impersonal data, generate graphs/reports and take data driven decisions.",
          "faqs_answer_02": "Tendbe is free of use for events voters yes! No account creation is necessary, we will not spam you with ads, you will only vote by scanning one of the QR options and if you wish to see the results, we kindly ask you to give us 3 impersonal data entries. So how do we make money? Well quite simply through our B2B clients subscriptions! The now famous freemium model! Event companies, fairs and conference organizers or simply brands wishing to engage their clients during those events or at street stands use Tendbe for the fastest most fun voting experience ever!",
          "faqs_answer_03": "Tendbe collects voters’ age, gender and occupation, this data is 100% non traceable, it is impossible for us or any of our clients to trace it back to you. These data entries are not mandatory and are left to the choice of the voters, although they are necessary for them to see results, but not for voting in itself. Tendbe does not ask voters to create an account, voting is 100% anonymous!",
          "faqs_answer_04": "",
          "faqs_answer_05": "Tendbe puts several security checkpoints to make sure no manipulation of results happen: No multiple votes from one device are possible, geographic/IP address restriction as well as safe encryption of the channels shared to avoid P2P hacking.",
          "faqs_answer_06": "Tendbe is interested in your geographic location, your age and gender (if you feel like sharing them with us :) ) nothing more, your full name, job, face photo however are not necessary, and are left to the freedom of our users, transparency is our motto. N.B: In case you encounter a Tendbe link or QR code and vote for it, only your geographic location matching your IP address will be stored.",
          "faqs_answer_07": "Upon deletion of a business account, Tendbe deletes all data reports generated by your account and stored in our database, as well as all account credentials and any polls you’ve created, your company has full ownership of your data.",
          "faqs_answer_08": "No, your comments will remain, but under the title “Tendbe user”, your username will be completely omitted, same goes for your profile photo, which will be replaced with a generic avatar.",
          "faqs_answer_09": "",
          "faqs_answer_10": "Your comments are anonymous, before writing them we ask you to quickly choose a name and an avatar under which you’ll write and reply to other comments. We leave it to you to stay anonymous or reveal yourself, you comment will stay as long as the poll isn’t deleted by its author. ",
          
          "contact": {
            "title": "Get in touch",
            "description": "We’re a start-up, and therefore would love to hear your feedback and answer your questions. Please feel free to drop us a line and we promise to get back to you as soon as possible, we speak English, Spanish, French, Arabic, Italian and Greek.",
            "sendMsgBtn": "Send Message"
          },
          "privacy.title": "Privacy Policy",
          "pricing":{
            "title":"Pricing",
            "subtitle":"Monthly subscriptions (Beta)",
          },
          "terms.title": "Terms & Conditions",
          "footer_menu_home_title": "Home",
          "footer_menu_terms_title": "Terms",
          "footer_menu_privacy_title": "Privacy",
          "footer_menu_faqs_title": "FAQ's",
          "footer_menu_contact_title": "Contact",
          "slider-section.title": "SHOW ME TENDBES!",
          "menu_title_home": "Home",
          "menu_title_how_it_works": "How it works",
          "menu_title_features": "Features",
          "menu_title_team": "Team",
          "menu_title_faqs": "FAQ's",
          "menu_title_contact": "Contact",
          "video_title": "Using Tendbe?", 
        }
        
      }     
      ,
      es: {
        translation: {
          "hero": {
            "part1": "Tu herramienta de encuestas multimedia",
            "part2": "¿Necesitas opiniones sobre tus productos o servicios?",
            "part3": "Quizás quieras pedirle a tus amigos y familiares una opinión rápida. ¡Solo haz un Tendbe!",
            "part4": "Prueba Tendbe"
          },
          "howitworks_title": "¿Como funciona?",
          "howitworks_description": "¡Dicen que una imagen vale más que mil palabras! Tendbe te permite crear encuestas de texto, pero también ir más allá, usa fotos, gifs, videos e incluso archivos de audio. ¡Genera enlaces y códigos QR y compártelos con todos! ¡Tendbe conecta opiniones por ti!",
          "howitworks_title_01": "Crea encuestas",
          "howitworks_title_02": "Crea Canales",
          "howitworks_title_03": "Comparte",
          "howitworks_title_04": "Consigue opiniones",
          "howitworks_title_05": "Consigue  estadísticas",
          "howitworks_description_01": "Crea encuestas usando texto, imágenes o videos, simple y rápido. Tendbe es en tu  app de votación favorita.",
          "howitworks_description_02": "Organiza tus encuestas en canales temáticos, reten e interactúa con tu audiencia, haz de tus canales una fuente inagotable de opiniones.",
          "howitworks_description_03": "Genera enlaces y códigos QR para tus encuestas y canales, compártelos en los lugares correctos. Tendbe te ayudará a dirigirte a tu audiencia de manera directa, eficiente, pero sobre todo divertida.",
          "howitworks_description_04": "Mira los resultados de tus encuestas, analizalos de manera clara e intuitiva, recibe comentarios valiosos, utiliza esta información para mejorar la toma de decisiones.",
          "howitworks_description_05": "Sumérgete en los resultados de tu encuesta utilizando los datos proporcionados voluntariamente por los encuestados. Visualiza las estadísticas de tu audiencia, como el género, la edad, las ubicaciones geográficas, el tiempo de votación y mucho más.",
          "features": "CARACTERÍSTICAS",
          "features_title_01": "Personalizar encuestas",
          "features_title_02": "Tendbe compass",
          "features_title_03": "Voto instantáneo",
          "features_title_04": "Estadisticas",
          "features_title_05": "Multimedia",
          "features_title_06": "Privacidad",
          "features_description_01": "Da forma a tus encuestas agregando un límite de tiempo, encuentra el grupo demográfico de tu elección agregando filtros de entrada a los encuestadores, como edad, sexo o estado civil.",
          "features_description_02": "¡Los canales en Tendbe Compass  desbloquean las opiniones del mundo que te rodea! ¿Comparte las encuestas con tu vecindario, un mercado al aire libre o un centro comercial, tu ciudad,incluso país?",
          "features_description_03": "¿Necesitas resultados directos a preguntas de sí / no? ¿Tu audiencia es del tipo que tiene prisa?Crea una encuesta con respuestas QR y la publicalo en lugares estratégicos?",
          "features_description_04": "Obtén la mejor información de los resultados de tus encuestas visualizando tus datos de la manera más clara y completa posible.",
          "features_description_05": "Tendbe te permite crear encuestas basadas en texto, pero puedes llevarlas al siguiente nivel utilizando videos, imágenes, gifs e incluso archivos de audio.",
          "features_description_06": "Vender tus datos no es lo nuestro. Tendbe te ofrece la <1>opción de permanecer anónimo o no</1>.  Aplicamos todas las medidas de seguridad para mantener tu confidencialidad. También garantizamos tu derecho de supresión  y eliminamos permanentemente tus rastros en Tendbe.",
          "sliderIllustrations": "¿Es un restaurante o un servicio de abastecimiento que desea recibir la retroalimentación instantánea y discreta de sus clientes en el lugar? ¿Necesitas la ayuda de tus amigos para elegir que ropa ponerte? ¿O simplemente buscas mejorar tus productos o servicios? ¡UnTendbe es apto para todas las situaciones y todo tipo de personas!",
          "video_title": "Las diferentes maneras de usar Tendbe",  
          "team": {
            "title": "NUESTRA AVENTURA",
            "description": "A principios de 2019, Tendbe fue creado con pasión por un equipo de emprendedores y nómadas digitales de todo el mundo. Hemos convertido en nuestra la difícil misión de democratizar el acceso a los datos al mismo tiempo que protegemos los derechos fundamentales de confidencialidad. Hacemos que sea más fácil votar, recopilar y analizar opiniones para un mundo más inclusivo con la mayor cantidad de personas posible participando en el proceso de toma de decisiones. Si  deseas saber más sobre nosotros, no dudes en contactarnos a traves de nuestro formulario de contacto."
          },
          "faqs.title": "PREGUNTAS FRECUENTES",
          "faqs_question_01": "¿Qué es Tendbe?",
          "faqs_question_02": "¿Tendbe es de uso gratuito? ¿Cómo generan ingresos?",
          "faqs_question_03": "¿Quién puede ver mis encuestas?",
          "faqs_question_04": "¿Puedo usar Tendbe para mi vida personal?",
          "faqs_question_05": "¿Cómo evita Tendbe votos falsos y resultados fraudulentos?",
          "faqs_question_06": "¿Qué hace Tendbe con mis datos?",
          "faqs_question_07": "¿Qué pasa cuando borro mi cuenta?",
          "faqs_question_08": "¿Se eliminarán mis comentarios una vez que desaparezca mi cuenta?",
          "faqs_question_09": "¿Me puede ayudar Tendbe a filtrar mis encuestas para  dirigirme a un determinado grupo de personas?",
          "faqs_question_10": "¿Puede Tendbe ayudarme a generar estadísticas y gráficos a partir de mis encuestas?",
          "faqs_answer_01": "Tendbe es una aplicación web de votación. Nuestro objetivo es brindarte una herramienta para crear encuestas multimedia y compartirlas con un grupo de tu elección.",
          "faqs_answer_02": "No cobramos ninguna tarifa por la creación de tu cuenta, ni por realizar encuestas, canales y/o compartirlos. Tampoco analizamos tus datos ni los vendemos. Buscamos llevar las cosas al siguiente nivel y ofrecer varios paquetes de servicios de pago para ofrecer características adicionales muy útiles e interesantes. Mantente en contacto con nosotros para más información, puedes llenar el formulario de contacto.-Serás el primero en enterarte de nuestras novedades.",
          "faqs_answer_03": "Personas con las que compartiste el enlace del canal o cualquier persona que escanee su código QR generado. También tienen la opción de estar atentos a tu canal, comentar e interactuar contigo siguiendo el conjunto de reglas que has establecido.",
          "faqs_answer_04": "Claro! crea Tendbes y compártelos con amigos y familiares para optimizar sus elecciones o simplemente para divertirse.  Usa Tendbe para cualquier cosa que desees, siempre que no incluya discursos de odio, incitación a la violencia o cualquier tipo de pornografía.",
          "faqs_answer_05": "Tendbe establece varios puntos de control de seguridad para asegurarse de que no se manipulen los resultados: límite de tiempo en las encuestas, restricción de direcciones geográficas / IP, etc., así como el cifrado seguro de los canales compartidos para evitar hacking P2P.",
          "faqs_answer_06": "Utilizamos tu ubicación geográfica, edad y sexo ( únicamente si deseas compartirlos con nosotros)  Datos como tunombre completo, trabajo, foto de perfil   no son necesarios, y quedan a libertad de nuestros usuarios si los usan o no.La transparencia en este aspecto es lo más importante para nosotros. Es importante mencionar de que en caso de que encuentres un enlace de Tendbe o un código QR en la calle, puedes votar toda tranquilidad.Solo será almacenada  la ubicación geográfica que coincide con la dirección IP.",
          "faqs_answer_07": "Creemos firmemente en el derecho de supresión de Internet, por lo tanto, establecimos Tendbe de manera que todos tus datos de registro (nombre de usuario, fotos, contraseña, etc.) así como todo el contenido de tu actividad (tus canales y encuestas) serán borrado de forma definitiva de nuestra base de datos.",
          "faqs_answer_08": "No, tus comentarios permanecerán, pero bajo el título “Tendbe user”, tu nombre de usuario será completamente omitido, lo mismo ocurre con tu foto de perfil, que será reemplazada por un avatar genérico.",
          "faqs_answer_09": "Sí, nuestra futura plataforma Tendbe premium proporcionará las herramientas para  hacer más específicas tus preguntas, enfocándose en datos demográficos específicos, como la población, edad y ubicación. Mantente  en contacto con nosotros si deseas estar actualizado sobre cualquiera de nuestras nuevas características y/o servicio llenando el formulario a continuación. ¡Gracias!",
          "faqs_answer_10": "¡Sí! Pero no por el momento :), Tendbe es una  app en desarrollo , pero estamos avanzando lento pero seguro hacia la creación de una plataforma premium B2B para este propósito. Con estos gráficos, Tendbe se convertirá en tu mejor herramienta de marketing y te ayudará a tomar decisiones basadas en datos que reflejan los deseos de tus clientes. Suscribete a nuestro boletín, síguenos en redes sociales (Facebook, Twitter) o envíenos un mensaje a traves del formulario de contacto.",
          "contact": {
            "title": "Ponte en contacto",
            "description": "Somos una Start-up y nos encantaría leer tus comentarios y responder a todas tus preguntas. No dudes en contactarnos,  responderemos lo antes posible. Hablamos inglés, español, francés, árabe, italiano y hasta griego :)",
            "sendMsgBtn": "Enviar Mensaje"
          },
          "privacy.title": "Política de privacidad",
          "terms.title": "Términos y condiciones",
          "footer_menu_home_title": "Inicio",
          "footer_menu_terms_title": "Términos",
          "footer_menu_privacy_title": "Privacidad",
          "footer_menu_faqs_title": "Preguntas frecuentes",
          "footer_menu_contact_title": "Contácto",
          "slider-section.title": "MUESTRAME TENDBES!",
          "menu_title_home": "Inicio",
          "menu_title_how_it_works": "Como funciona",
          "menu_title_features": "Caracteristicas",
          "menu_title_team": "Equipo",
          "menu_title_faqs": "Preguntas frecuentes",
          "menu_title_contact": "Contacto"
        }
      }
    },
    fallbackLng: 'en',  
    debug: true,    
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

export default i18n;