import illustration1 from "../static/illustrations/image001.png";
import illustration2 from "../static/illustrations/image003.png";
import illustration3 from "../static/illustrations/image004.png";

import screenshot1 from "../static/mocks/slide01.png";
import screenshot2 from "../static/mocks/slide02.png";
import screenshot3 from "../static/mocks/slide03.png";
import screenshot4 from "../static/mocks/slide04.png";

export const sliderSection = {
  title: "Show me tendbes!",
};

export const sliderIllustrations = {
  description:
    "Are you a restaurant or a catering service wishing to receive your client’s instantaneous and discrete feedback on the spot? Do you need the help of your close friends circle to choose your outfit? Or simply seeking to improve your services or product?  A Tendbe poll is fit for all situations and people!",
  images: [
    {
      src: illustration1,
      alt: "",
    },
    {
      src: illustration2,
      alt: "",
    },
    {
      src: illustration3,
      alt: "",
    },
  ],
};

export const sliderScreenshots = {
  images: [
    {
      src: screenshot1,
      alt: "",
    },
    {
      src: screenshot2,
      alt: "",
    },
    {
      src: screenshot3,
      alt: "",
    },
    {
      src: screenshot4,
      alt: "",
    },
  ],
};
