import styled, { css } from "styled-components";
import { device } from "../../../utils/device";

export const SliderWrapper = styled.section(
  ({ theme }) => css`
    background: ${theme.colors.tbBlue.base};
  `
);

export const SliderContainer = styled.div(
  ({theme}) => css`
    box-sizing: border-box;
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
    padding: 2.5rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    @media ${device.laptop} {
      flex-direction: row;
    }
  `,
);

export const SliderDescription = styled.div(
  ({ theme }) => css`
    width: 100%;
    padding: 0;
    & p {
      color: ${theme.colors.white};
      margin: 0;
      font-size: ${theme.typography.sizes.large};
      line-height: 1.714;
      text-align: justify;
    }
    @media ${device.laptop} {
      width: 50%;
      padding: 0 3rem;
    }
  `
);

export const SliderCarousel = styled.div`
  width: 100%;
  padding: 0;
  @media ${device.laptop} {
    width: 50%;
    padding: 0 2rem;
  }
`;

export const SliderImage = styled.img`
  width: 100%;
  object-fit: cover;
`;