import React from "react";
import { useTranslation } from "react-i18next";
import { sliderIllustrations } from "../../../constants/slider";
import Carousel from "../../molecules/Slider/Carousel.component";
import { SliderCarousel, SliderContainer, SliderDescription, SliderImage, SliderWrapper } from "./Slider.styled";


const Slider = () => {
  const { t } = useTranslation();
  
  return (
    <SliderWrapper>
      <SliderContainer>
        <SliderCarousel>
          <Carousel autoScroll autoScrollInterval={5000} variant="white">
            {sliderIllustrations.images.map((image, index) => {
              const { src, alt } = image;
              return <SliderImage src={src} alt={alt} key={index} />;
            })}
          </Carousel>
        </SliderCarousel>
        <SliderDescription>
          <p>{t("sliderIllustrations")}</p>
        </SliderDescription>
      </SliderContainer>
    </SliderWrapper>
  );
};

export default Slider;
